"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250122195812/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/home/devops/repos/1_20250122195812/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("./index.scss");
var _vuex = require("vuex");
var _dashboard = require("@/api/dashboard");
var _order = require("@/api/order");
var _jurisdictionx = require("@/api/jurisdictionx");
var _constants = _interopRequireDefault(require("../../utils/constants"));
var _CommonElDrawer = _interopRequireDefault(require("@/components/CommonElDrawer"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'DashboardEditor',
  components: {
    commonElDrawer: _CommonElDrawer.default
  },
  data: function data() {
    return {
      tabPosition: 'left',
      tabList: [],
      monthList: [],
      activeName: '',
      limit: 60,
      page: 1,
      motto: [],
      leftChoosed: 0,
      drawerVisible: false,
      boardNum: {},
      searchForm: {
        page: 1,
        limit: 100,
        eventOperate: 1
      },
      totalPages: 0,
      choosed: null,
      // 工单部分
      boardInfo: [],
      // 快速处理
      fastVisible: false,
      fastInfo: {},
      fastForm: {},
      userselectList: [],
      // 历史工单
      historyVisible: false,
      historyTbaleList: [],
      historyTbaleList0: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)(['app'])),
  mounted: function mounted() {
    // 获取指定元素
    var scrollview = this.$refs['tab'];
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    scrollview.addEventListener('scroll', this.scrollChange, true);
  },
  created: function created() {
    //
    this.$store.dispatch('app/setdistrict'); // 地址获取
    this.user = this.$store.getters.userinfo;
    // this.webSocket()
    this.detail();
    this.getBoardTotal();
    this.changeTabs(2);
    this.getUserList();
    this.motto = _constants.default.motto;
  },
  beforeDestroy: function beforeDestroy() {
    // 获取指定元素
    var scrollview = this.$refs['tab'];
    // 移除监听
    scrollview.removeEventListener('scroll', this.scrollChange, true);
  },
  methods: {
    // 获取任务数量
    getBoardTotal: function getBoardTotal() {
      var _this = this;
      (0, _order.mallOrderEvent_statusTotal)().then(function (res) {
        _this.boardNum = res.data;
      });
    },
    // 获取用户列表
    getUserList: function getUserList() {
      var _this2 = this;
      (0, _jurisdictionx.userList2)({
        'page': 1,
        'limit': 1000,
        'userName': ''
      }).then(function (res) {
        _this2.userselectList = res.data.records;
      });
    },
    // 加载log
    getOrderLog: function getOrderLog(orderId) {
      var _this3 = this;
      (0, _order.mallOrderEvent_log)(orderId).then(function (res) {
        _this3.historyTbaleList = res.data;
        _this3.historyTbaleList0 = [res.data[0]];
      });
    },
    // 查询
    search: function search() {
      this.loading = true;
      // 处理时间
      if (this.searchForm.time && this.searchForm.time !== null) {
        this.searchForm.originalTimeFrom = this.searchForm.time[0]; // 提交开始时间
        this.searchForm.originalTimeTo = this.searchForm.time[0]; // 提交截至时间
      }
      this.getBoardInfo();
    },
    // 重置查询
    resetSearch: function resetSearch() {
      this.searchForm = {
        page: 1,
        limit: 100,
        eventOperate: this.searchForm.eventOperate
      };
      this.getBoardInfo();
    },
    // 切换事件
    changeTabs: function changeTabs(type) {
      this.choosed = null;
      this.searchForm = {
        page: 1,
        limit: 100,
        eventOperate: type
      };
      this.getBoardInfo();
    },
    // 获取对应看板下列表数据
    getBoardInfo: function getBoardInfo() {
      var _this4 = this;
      (0, _order.mallOrderEvent_eventsV2)(this.searchForm).then(function (res) {
        setTimeout(function () {
          _this4.loading = false;
        }, 100);
        if (res.data.list !== []) {
          _this4.boardInfo = res.data.list;
          // 给每个工单添加快速处理,标签页
          for (var i in _this4.boardInfo) {
            var fastForm = {
              employeeId: '',
              remark: '',
              opreate: null
            };
            _this4.$set(_this4.boardInfo[i], 'fastForm', fastForm);
            _this4.$set(_this4.boardInfo[i], 'tabs', '0');
          }
          console.log('-------this.boardInfo', _this4.boardInfo);
        } else {
          _this4.boardInfo = [];
        }
      });
    },
    changeOrder: function changeOrder(item, index) {
      this.choosed = index;
      var parms = {
        employeeId: '',
        opreate: '',
        remark: ''
      };
      this.fastInfo = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
        parms: parms
      });
      this.fastVisible = true;
      this.getOrderLog(item.orderId);
    },
    // 提交快速处理
    fastSub: function fastSub() {
      var _this5 = this;
      console.log(this.fastInfo);
      var parms = {
        employeeId: this.fastInfo.employeeId,
        opreate: this.fastInfo.opreate,
        remark: this.fastInfo.remark,
        eventType: this.fastInfo.eventType,
        id: this.fastInfo.eventId,
        orderId: this.fastInfo.orderId
      };
      (0, _order.mallOrderEvent_update)(parms).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.boardInfo.splice(_this5.choosed, 1);
        _this5.$nextTick(function () {
          _this5.fastVisible = false;
          _this5.getBoardTotal();
        });
      });
    },
    openHistory: function openHistory() {
      this.historyVisible = true;
      this.getOrderLog(this.fastInfo.orderId);
    },
    toOrderPlay: function toOrderPlay(item) {
      if (item.orderType === 1) {
        this.$router.push({
          path: '/mall/order/orderPlay',
          query: {
            id: item.orderId,
            returnTo: true
          }
        });
      }
      if (item.orderType === 2) {
        this.$router.push({
          path: '/wholesale/wholesale/orderManager/orderPlay',
          query: {
            id: item.orderId,
            returnTo: true
          }
        });
      }
      if (item.orderType === 7) {
        this.$router.push({
          path: '/SZXQ/SZXQ/orderPlay',
          query: {
            id: item.orderId,
            returnTo: true
          }
        });
      }
    },
    // =========================================================================================================================================
    // 滚动
    scrollChange: function scrollChange() {
      var scrollTop = document.querySelector('#tab').scrollTop;
      this.tabList[this.leftChoosed].curScrollTop = scrollTop;
    },
    // 左侧切换
    changeLeft: function changeLeft(row, index) {
      this.leftChoosed = index;
      this.choose(row);
    },
    // 当月的数据
    choose: function choose(e) {
      var _this6 = this;
      var choosereq = {
        'yearMonth': e.yearMonth,
        page: this.page,
        limit: this.limit
      };
      (0, _jurisdictionx.releaseList)(choosereq).then(function (res) {
        _this6.monthList = res.data.list;
        _this6.total = parseInt(res.data.total);
        _this6.$refs.tab.scrollTop = e.curScrollTop;
      });
    },
    // 月份的列表
    detail: function detail() {
      var _this7 = this;
      (0, _dashboard.releaseMonth)().then(function (res) {
        for (var i in res.data) {
          _this7.$set(res.data[i], 'curScrollTop ', 0);
        }
        _this7.tabList = res.data;
        _this7.activeName = _this7.tabList[0].yearMonth;
        _this7.tabList[0].flag = true;
        _this7.choose(_this7.tabList[0].yearMonth);
      });
    },
    scrollBottom: function scrollBottom(e) {
      var self = this;
      var Scroll = e.target;
      // 网页可见区域高：document.body.clientHeight
      // 网页正文全文高：document.body.scrollHeight
      var scrollHeight = Scroll.scrollHeight - Scroll.clientHeight;
      self.scrollTop = Scroll.scrollTop;
      if (scrollHeight - Scroll.scrollTop < 100 && !self.loadflag) {
        console.log('到底部了');
        self.loadflag = true;
        self.page++;
        self.loadData();
      }
    },
    webSocket: function webSocket() {
      var that = this;
      if (typeof WebSocket === 'undefined') {
        this.$notify({
          title: '提示',
          message: '当前浏览器无法接收实时报警信息，请使用谷歌浏览器！',
          type: 'warning',
          duration: 0
        });
      } else {
        // 实例化socket，这里我把用户名传给了后台，使后台能判断要把消息发给哪个用户，其实也可以后台直接获取用户IP来判断并推送
        var socketUrl = process.env.VUE_APP_BASE_WSS + '/' + this.user.userId;
        this.socket = new WebSocket(socketUrl);
        console.log('socketUrl', socketUrl);
        // 监听socket打开
        this.socket.onopen = function () {
          console.log('浏览器WebSocket已打开', new Date());
        };
        // 监听socket消息接收
        this.socket.onmessage = function (msg) {
          // 转换为json对象
          that.$notify({
            title: '来新任务啦！',
            // 这里也可以把返回信息加入到message中显示
            message: '你有新的任务需要尽快处理！',
            type: 'warning',
            duration: 0,
            position: 'bottom-right',
            onClick: function onClick() {
              that.$router.push({
                path: '/order/order/orderBoard'
              });
            }
          });
        };
        // 监听socket错误
        this.socket.onerror = function () {
          console.log('服务器错误，无法接收实时报警信息', new Date());
        };
        // 监听socket关闭
        this.socket.onclose = function () {
          console.log('WebSocket已关闭', new Date());
          that.webSocket();
        };
      }
    }
  }
};