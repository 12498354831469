"use strict";

var _interopRequireDefault = require("/home/devops/repos/1_20250122195812/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pubilc = require("@/api/system/pubilc");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = exports.default = {
  name: 'Download',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      proList: [],
      listLoading: false,
      total: 0,
      // 总条数
      type: '',
      status: '',
      tenantName: '',
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },
  created: function created() {
    this.loadPro();
  },
  methods: {
    loadPro: function loadPro() {
      var _this = this;
      this.listLoading = true;
      (0, _pubilc.sysDownload_list)(this.listQuery).then(function (res) {
        _this.proList = [];
        _this.proList = res.data.list;
        _this.total = parseInt(res.data.total);
        setTimeout(function () {
          _this.listLoading = false;
        }, 0.5 * 1000);
      });
    },
    download: function download(item) {
      window.open(item.downloadUrl);
    }
  }
};